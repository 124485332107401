<template>
    <ul>
        <component :is="resolveNavItemComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item" />
    </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import _ from "lodash";
export default {
    components: {
        VerticalNavMenuHeader,
        VerticalNavMenuLink,
        VerticalNavMenuGroup,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        provide("openGroups", ref([]));
        let userPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((i) => {
            return i.name;
        });
        // console.log(userPermissions);
        // console.log(props.items);

        //Roles
        let roleData = [];
        props.items[2].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                roleData.push(item);
            }
            props.items[2].children = roleData;
        });
        if (!userPermissions.includes("Role.roles.create") && !userPermissions.includes("Role.roles.list") && !userPermissions.includes("Role.roles.assign")) {
            props.items[1].header = " ";
        }
        //user
        if (!userPermissions.includes("User.add")) {
            props.items[4].children.splice(0, 1);
        }
        if (!userPermissions.includes("User.list")) {
            if (!userPermissions.includes("User.add")) {
                props.items[4].children.splice(0, 1);
            } else {
                props.items[4].children.splice(1, 1);
            }
        }
        //artist singer
        if (!userPermissions.includes("Artist.Singer.add")) {
            props.items[5].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Artist.Singer.list")) {
            if (!userPermissions.includes("Artist.Singer.add")) {
                props.items[5].children[0].children.splice(0, 1);
            } else {
                props.items[5].children[0].children.splice(1, 1);
            }
        }
        //artist composer list
        if (!userPermissions.includes("Artist.Composer-list.add")) {
            props.items[5].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Artist.Composer-list.list")) {
            if (!userPermissions.includes("Artist.Composer-list.add")) {
                props.items[5].children[1].children.splice(0, 1);
            } else {
                props.items[5].children[1].children.splice(1, 1);
            }
        }
        //artist composer home
        if (!userPermissions.includes("Artist.Composer-home.add")) {
            props.items[5].children[2].children.splice(0, 1);
        }
        if (!userPermissions.includes("Artist.Composer-home.list")) {
            if (!userPermissions.includes("Artist.Composer-home.add")) {
                props.items[5].children[2].children.splice(0, 1);
            } else {
                props.items[5].children[2].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Artist.Singer.add") && !userPermissions.includes("Artist.Singer.list") && !userPermissions.includes("Artist.Composer-list.add") && !userPermissions.includes("Artist.Composer-list.list") && !userPermissions.includes("Artist.Composer-home.add") && !userPermissions.includes("Artist.Composer-home.add")) {
            props.items[5].children = [];
        }
        //music home
        if (!userPermissions.includes("Music.Home.add")) {
            props.items[6].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Music.Home.list")) {
            if (!userPermissions.includes("Music.Home.add")) {
                props.items[6].children[0].children.splice(0, 1);
            } else {
                props.items[6].children[0].children.splice(1, 1);
            }
        }
        //music browse
        if (!userPermissions.includes("Music.Browse.add")) {
            props.items[6].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Music.Browse.list")) {
            if (!userPermissions.includes("Music.Browse.add")) {
                props.items[6].children[1].children.splice(0, 1);
            } else {
                props.items[6].children[1].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Music.Home.add") && !userPermissions.includes("Music.Home.list") && !userPermissions.includes("Music.Browse.add") && !userPermissions.includes("Music.Browse.list")) {
            // props.items[6].children = [];
            props.items[6].children ? (props.items[6].children = []) : props.items[6].children;
        }
        //video home
        if (!userPermissions.includes("Video.Home.add")) {
            props.items[7].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Video.Home.list")) {
            if (!userPermissions.includes("Video.Home.add")) {
                props.items[7].children[0].children.splice(0, 1);
            } else {
                props.items[7].children[0].children.splice(1, 1);
            }
        }
        //video browse
        if (!userPermissions.includes("Video.Browse.add")) {
            props.items[7].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Video.Browse.list")) {
            if (!userPermissions.includes("Video.Browse.add")) {
                props.items[7].children[1].children.splice(0, 1);
            } else {
                props.items[7].children[1].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Video.Home.add") && !userPermissions.includes("Video.Home.list") && !userPermissions.includes("Video.Browse.add") && !userPermissions.includes("Video.Browse.list")) {
            props.items[7].children ? (props.items[7].children = []) : props.items[7].children;
        }
        //genre browse
        if (!userPermissions.includes("Genre.add")) {
            props.items[8].children.splice(0, 1);
        }
        if (!userPermissions.includes("Genre.list")) {
            if (!userPermissions.includes("Genre.add")) {
                props.items[8].children.splice(0, 1);
            } else {
                props.items[8].children.splice(1, 1);
            }
        }
        //radio stations
        if (!userPermissions.includes("Radio-Stations.add")) {
            props.items[9].children.splice(0, 1);
        }
        if (!userPermissions.includes("Radio-Stations.list")) {
            if (!userPermissions.includes("Radio-Stations.add")) {
                props.items[9].children.splice(0, 1);
            } else {
                props.items[9].children.splice(1, 1);
            }
        }
        //special items
        if (!userPermissions.includes("Special-items.add")) {
            props.items[10].children.splice(0, 1);
        }
        if (!userPermissions.includes("Special-items.list")) {
            if (!userPermissions.includes("Special-items.add")) {
                props.items[10].children.splice(0, 1);
            } else {
                props.items[10].children.splice(1, 1);
            }
        }
        //playlist home
        if (!userPermissions.includes("Playlist.Home.add")) {
            props.items[11].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Playlist.Home.list")) {
            if (!userPermissions.includes("Playlist.Home.add")) {
                props.items[11].children[0].children.splice(0, 1);
            } else {
                props.items[11].children[0].children.splice(1, 1);
            }
        }
        //playlist browse
        if (!userPermissions.includes("Playlist.Browse.add")) {
            props.items[11].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Playlist.Browse.list")) {
            if (!userPermissions.includes("Playlist.Browse.add")) {
                props.items[11].children[1].children.splice(0, 1);
            } else {
                props.items[11].children[1].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Playlist.Browse.add") && !userPermissions.includes("Playlist.Browse.list") && !userPermissions.includes("Playlist.Home.add") && !userPermissions.includes("Playlist.Home.add")) {
            props.items[11].children ? (props.items[11].children = []) : props.items[11].children;
        }

        //mood home
        if (!userPermissions.includes("Mood.Home.add")) {
            props.items[12].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Mood.Home.list")) {
            if (!userPermissions.includes("Mood.Home.add")) {
                props.items[12].children[0].children.splice(0, 1);
            } else {
                props.items[12].children[0].children.splice(1, 1);
            }
        }
        //mood browse
        if (!userPermissions.includes("Mood.Browse.add")) {
            props.items[12].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Mood.Browse.list")) {
            if (!userPermissions.includes("Mood.Browse.add")) {
                props.items[12].children[1].children.splice(0, 1);
            } else {
                props.items[12].children[1].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Mood.Home.add") && !userPermissions.includes("Mood.Home.list") && !userPermissions.includes("Mood.Browse.add") && !userPermissions.includes("Mood.Browse.list")) {
            props.items[12].children ? (props.items[12].children = []) : props.items[7].children;
        }
        //banner slide show
        if (!userPermissions.includes("Banners.Slide-show.add")) {
            props.items[13].children[0].children.splice(0, 1);
        }
        if (!userPermissions.includes("Banners.Slide-show.all-list")) {
            if (!userPermissions.includes("Banners.Slide-show.add")) {
                props.items[13].children[0].children.splice(0, 1);
            } else {
                props.items[13].children[0].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Banners.Slide-show.active-list")) {
            if (!userPermissions.includes("Banners.Slide-show.add") && !userPermissions.includes("Banners.Slide-show.all-list")) {
                props.items[13].children[0].children.splice(0, 1);
            } else if (userPermissions.includes("Banners.Slide-show.add") && userPermissions.includes("Banners.Slide-show.all-list")) {
                props.items[13].children[0].children.splice(2, 1);
            } else if (!userPermissions.includes("Banners.Slide-show.add") && userPermissions.includes("Banners.Slide-show.all-list")) {
                props.items[13].children[0].children.splice(1, 1);
            } else if (!userPermissions.includes("Banners.Slide-show.all-list") && !userPermissions.includes("Banners.Slide-show.add")) {
                props.items[13].children[0].children.splice(1, 1);
            }
        }
        //banner coming soon
        if (!userPermissions.includes("Banners.Coming-soon.add")) {
            props.items[13].children[1].children.splice(0, 1);
        }
        if (!userPermissions.includes("Banners.Coming-soon.all-list")) {
            if (!userPermissions.includes("Banners.Coming-soon.add")) {
                props.items[13].children[1].children.splice(0, 1);
            } else {
                props.items[13].children[1].children.splice(1, 1);
            }
        }
        // if (!userPermissions.includes("Banners.Coming-soon.active-list")) {
        //     if (!userPermissions.includes("Banners.Coming-soon.add") && !userPermissions.includes("Banners.Coming-soon.all-list")) {
        //         props.items[13].children[1].children.splice(0, 1);
        //     } else if (userPermissions.includes("Banners.Coming-soon.add")) {
        //         props.items[13].children[1].children.splice(1, 1);
        //     } else if (userPermissions.includes("Banners.Coming-soon.all-list")) {
        //         props.items[13].children[1].children.splice(1, 1);
        //     }
        // }
        if (!userPermissions.includes("Banners.Coming-soon.active-list")) {
            if (!userPermissions.includes("Banners.Coming-soon.add") && !userPermissions.includes("Banners.Coming-soon.all-list")) {
                props.items[13].children[1].children.splice(0, 1);
            } else if (userPermissions.includes("Banners.Coming-soon.add") && userPermissions.includes("Banners.Coming-soon.all-list")) {
                props.items[13].children[1].children.splice(2, 1);
            } else if (!userPermissions.includes("Banners.Coming-soon.add") && userPermissions.includes("Banners.Coming-soon.all-list")) {
                props.items[13].children[1].children.splice(1, 1);
            } else if (!userPermissions.includes("Banners.Coming-soon.all-list") && !userPermissions.includes("Banners.Slide-show.add")) {
                props.items[13].children[1].children.splice(1, 1);
            }
        }
        //banner statics
        if (!userPermissions.includes("Banners.Statics.add")) {
            props.items[13].children[2].children.splice(0, 1);
        }
        if (!userPermissions.includes("Banners.Statics.list")) {
            if (!userPermissions.includes("Banners.Statics.add")) {
                props.items[13].children[2].children.splice(0, 1);
            } else {
                props.items[13].children[2].children.splice(1, 1);
            }
        }
        if (!userPermissions.includes("Banners.Slide-show.add") && !userPermissions.includes("Banners.Slide-show.all-list") && !userPermissions.includes("Banners.Slide-show.active-list") && !userPermissions.includes("Banners.Coming-soon.add") && !userPermissions.includes("Banners.Coming-soon.all-list") && !userPermissions.includes("Banners.Coming-soon.active-list") && !userPermissions.includes("Banners.Statics.add") && !userPermissions.includes("Banners.Statics.list")) {
            props.items[13].children = [];
        }
        //STICKER PACK
        if (!userPermissions.includes("Sticker.Pack.add")) {
            props.items[14].children.splice(0, 1);
        }
        if (!userPermissions.includes("Sticker.Pack.list")) {
            if (!userPermissions.includes("Sticker.Pack.add")) {
                props.items[14].children.splice(0, 1);
            } else {
                props.items[14].children.splice(1, 1);
            }
        }
        //TRENDING
        let trendData = [];
        props.items[15].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                trendData.push(item);
            }
            props.items[15].children = trendData;
        });
        //notification
        let notifData = [];
        props.items[16].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                notifData.push(item);
            }
            props.items[16].children = notifData;
        });
        //news home
        let newsHomeData = [];
        props.items[17].children[0].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                newsHomeData.push(item);
            }
            props.items[17].children[0].children = newsHomeData;
        });
        //news browse
        let newsBrowseData = [];
        props.items[17].children[1].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                newsBrowseData.push(item);
            }
            props.items[17].children[1].children = newsBrowseData;
        });
        if (newsHomeData.length == 0 && newsBrowseData.length == 0) {
            props.items[17].children = [];
        }
        //latest
        let latestData = [];
        props.items[18].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                latestData.push(item);
            }
            props.items[18].children = latestData;
        });
        //featured
        let featuredData = [];
        props.items[19].children.map(async (item, index) => {
            if (userPermissions.includes(item.permission)) {
                featuredData.push(item);
            }
            props.items[19].children = featuredData;
        });
        //live chat
        let liveData = [];
        if (!userPermissions.includes("liveChat.view-live-channel")) {
            props.items[20].children.splice(0, 1);
        }
        if (!userPermissions.includes("liveChat.add") || !userPermissions.includes("liveChat.list")) {
            if (!userPermissions.includes("liveChat.view-live-channel")) {
                props.items[20].children[0].children.map(async (item, index) => {
                    if (userPermissions.includes(item.permission)) {
                        liveData.push(item);
                    }
                    props.items[20].children[0].children = liveData;
                });
            } else {
                props.items[20].children[1].children.map(async (item, index) => {
                    if (userPermissions.includes(item.permission)) {
                        liveData.push(item);
                    }
                    props.items[20].children[1].children = liveData;
                });
            }
        }
        if (!userPermissions.includes("liveChat.add") && !userPermissions.includes("liveChat.list") && !userPermissions.includes("liveChat.view-live-channel")) {
            props.items[20].children = [];
        }
        if (!userPermissions.includes("Comments.list")) {
            props.items[21].children = [];
        }
        if (!userPermissions.includes("Posts.list")) {
            props.items[22].children = [];
        }
        if (!userPermissions.includes("Stories.list")) {
            props.items[23].children = [];
        }
        if (!userPermissions.includes("Version.add")) {
            props.items[24].children = [];
        }

        if (!userPermissions.includes("Setting.show")) {
            props.items[25].children = [];
        }
        //wallet
        // let walletData = [];
        // props.items[28].children.map(async (item, index) => {
        //     if (userPermissions.includes(item.permission)) {
        //         walletData.push(item);
        //     }
        //     props.items[28].children = walletData;
        // });
        // if (!userPermissions.includes("Wallet.wallet.show-user-wallet") && !userPermissions.includes("Wallet.currency.add") && !userPermissions.includes("Wallet.admin-logs.list") && !userPermissions.includes("Wallet.payment.total-payments")) {
        //     props.items[27].header = " ";
        // }
        return {
            resolveNavItemComponent,
        };
    },
};
</script>
