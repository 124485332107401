export default [
    {
        header: 'Dashboards',
        icon: 'HomeIcon',
        children: [
            {
                title: 'eCommerce',
                route: 'dashboard-ecommerce',
                icon: 'ShoppingCartIcon',
            },
            {
                title: 'Analytics',
                route: 'dashboard',
                icon: 'ActivityIcon',
            },
        ],
    },
]
