export default [
    {
        header: "Access Levels",
    },
    {
        title: "Roles",
        icon: "BookOpenIcon",
        children: [
            {
                title: "New Role",
                route: "access-level-add",
                permission: "Role.roles.create",
            },
            {
                title: "Role List",
                route: "access-level-list",
                permission: "Role.roles.list",
            },
            {
                title: "Assign Role",
                route: "access-level-assign",
                permission: "Role.roles.assign",
            },
        ],
    },

    {
        header: "Apps & Pages",
    },
    {
        title: "User",
        icon: "UserIcon",
        children: [
            {
                title: "Add",
                route: "apps-users-add",
            },
            {
                title: "List",
                route: "apps-users-list",
            },
        ],
    },
    // {
    //     title: "Artist",
    //     icon: "UserIcon",
    //     children: [
    //         {
    //             title: "Add",
    //             route: "apps-artists-add",
    //         },
    //         {
    //             title: "List",
    //             route: "apps-artists-list",
    //         },
    //     ],
    // },
    {
        title: "Artist",
        icon: "MicIcon",
        children: [
            {
                title: "Singer",
                children: [
                    {
                        title: "Add",
                        route: "apps-artists-add",
                    },
                    {
                        title: "List",
                        route: "apps-artists-list",
                    },
                ],
            },
            {
                title: "Composer List",
                children: [
                    {
                        title: "Add",
                        route: "apps-composer-add",
                    },
                    {
                        title: "List",
                        route: "apps-composer-list",
                    },
                ],
            },
            {
                title: "Composer Home",
                children: [
                    {
                        title: "Add",
                        route: "composer-special-item-add",
                    },
                    {
                        title: "List",
                        route: "composer-special-item-list",
                    },
                ],
            },
        ],
    },
    {
        title: "Music",
        icon: "MusicIcon",
        children: [
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "music-special-item-add",
                    },
                    {
                        title: "List",
                        route: "music-special-item-list",
                    },
                ],
            },
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-musics-add",
                    },
                    {
                        title: "List",
                        route: "apps-musics-list",
                    },
                ],
            },
        ],
    },
    {
        title: "Video",
        icon: "VideoIcon",
        children: [
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "video-special-item-add",
                    },
                    {
                        title: "List",
                        route: "video-special-item-list",
                    },
                ],
            },
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-videos-add",
                    },
                    {
                        title: "List",
                        route: "apps-videos-list",
                    },
                ],
            },
        ],
    },
    {
        title: "Genres",
        icon: "ListIcon",
        children: [
            {
                title: "Add",
                route: "apps-genres-add",
            },
            {
                title: "List",
                route: "apps-genres-list",
            },
        ],
    },
    {
        title: "Radio Stations",
        icon: "RadioIcon",
        children: [
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-radio-station-add",
                    },
                    {
                        title: "List",
                        route: "apps-radio-station-list",
                    },
                ],
            },
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "apps-radio-station-special-item-add",
                    },
                    {
                        title: "List",
                        route: "apps-radio-station-special-item-list",
                    },
                ],
            },
        ],
    },
    {
        title: "Special Items",
        icon: "StarIcon",
        children: [
            {
                title: "Add",
                route: "apps-special-item-add",
            },
            {
                title: "List",
                route: "apps-special-item-list",
            },
        ],
    },
    {
        title: "Playlist",
        icon: "PlayCircleIcon",
        children: [
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "playlist-special-item-add",
                    },
                    {
                        title: "List",
                        route: "playlist-special-item-list",
                    },
                ],
            },
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-play-list-add",
                    },
                    {
                        title: "List",
                        route: "apps-play-list-list",
                    },
                ],
            },
            // {
            //     title: "Convert to Mood",
            //     route: "apps-play-list-convert",
            // },
        ],
    },
    {
        title: "Moods",
        icon: "SmileIcon",
        children: [
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "moods-special-item-add",
                    },
                    {
                        title: "List",
                        route: "moods-special-item-list",
                    },
                ],
            },
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-moods-add",
                    },
                    {
                        title: "List",
                        route: "apps-moods-list",
                    },
                ],
            },
        ],
    },
    {
        title: "Banners",
        icon: "LayoutIcon",
        children: [
            {
                title: "Slide Shows",
                children: [
                    {
                        title: "Add Slide Show",
                        route: "apps-slide-show-add",
                    },
                    {
                        title: "All List",
                        route: "apps-slide-show-list",
                    },
                    {
                        title: "Actives List",
                        route: "apps-slide-show-active-list",
                    },
                ],
            },
            {
                title: "Coming Soon",
                children: [
                    {
                        title: "Add Coming Soon",
                        route: "apps-comming-soon-add",
                    },
                    {
                        title: "All Coming Soon List",
                        route: "apps-comming-soon-list",
                    },
                    {
                        title: "Actives List",
                        route: "apps-comming-soon-active-list",
                    },
                ],
            },
            {
                title: "Statics",
                children: [
                    {
                        title: "Add",
                        route: "apps-static-banners-add",
                    },
                    {
                        title: "List",
                        route: "apps-static-banners-list",
                    },
                ],
            },
        ],
    },
    // {
    //     title: "Monetization",
    //     icon: "DollarSignIcon",
    // children: [
    //     {
    //         title: "Sliders",
    //         route: "apps-monetization-slider",
    //     },
    //     {
    //         title: "Banner",
    //         route: "apps-monetization-banner",
    //     },
    // ],
    // },
    {
        title: "Sticker Pack",
        icon: "GithubIcon",
        children: [
            {
                title: "add",
                route: "apps-sticker-pack-add",
            },
            {
                title: "list",
                route: "apps-sticker-pack-list",
            },
        ],
    },
    {
        title: "Trending",
        icon: "TrendingUpIcon",
        children: [
            {
                title: "Real Trends",
                route: "apps-trending-real",
                permission: "Trending.Real-trends.list",
            },
            {
                title: "Custom Trends",
                route: "apps-trending-insert",
                permission: "Trending.Custom-trends.add",
            },
            {
                title: "Custom List",
                route: "apps-trending-list",
                permission: "Trending.Custom-trends.list",
            },
            {
                title: "Final Trends List",
                route: "apps-trending-final",
                permission: "Trending.Final-trends.list",
            },
            {
                title: "Next Final Trends",
                route: "apps-trending-next-final",
                permission: "Trending.Next-Final-trends.list",
            },
            {
                title: "Trend Options",
                route: "apps-trending-coefficient",
                permission: "Trending.Trends-options.show",
            },
        ],
    },
    {
        title: "Notifications",
        icon: "BellIcon",
        children: [
            {
                title: "Push",
                route: "apps-notification-add",
                permission: "Notifications.Push",
            },
            {
                title: "Report List",
                route: "apps-notification-list",
                permission: "Notifications.Report-list",
            },
        ],
    },
    {
        title: "News",
        icon: "RadioIcon",
        children: [
            {
                title: "Home",
                children: [
                    {
                        title: "Add",
                        route: "news-special-item-add",
                        permission: "News.Home.add",
                    },
                    {
                        title: "List",
                        route: "news-special-item-list",
                        permission: "News.Home.list",
                    },
                ],
            },
            {
                title: "Browse",
                children: [
                    {
                        title: "Add",
                        route: "apps-news-add",
                        permission: "News.Browse.add",
                    },
                    {
                        title: "List",
                        route: "apps-news-list",
                        permission: "News.Browse.list",
                    },
                ],
            },
        ],
    },
    // {
    //     title: "Ads",
    //     icon: "UploadCloudIcon",
    //     children: [
    //         {
    //             title: "Google Ads",
    // children: [
    //     {
    //         title: "Setting",
    //         route: "apps-google-adds-setting",
    //     },
    // ],
    //         },
    //     ],
    // },
    {
        title: "Latest",
        icon: "CornerLeftUpIcon",
        children: [
            {
                title: "Add",
                route: "latest-special-item-add",
                permission: "Latest.add",
            },
            {
                title: "List",
                route: "latest-special-item-list",
                permission: "Latest.list",
            },
        ],
    },
    {
        title: "Featured",
        icon: "CheckSquareIcon",
        children: [
            {
                title: "Add",
                route: "featured-special-item-add",
                permission: "Featured.add",
            },
            {
                title: "List",
                route: "featured-special-item-list",
                permission: "Featured.list",
            },
        ],
    },
    {
        title: "Live Chat",
        icon: "CastIcon",
        children: [
            {
                title: "Live Channel",
                route: "apps-live-chat",
                permission: "liveChat.view-live-channel",
            },
            {
                title: "Set Live",
                children: [
                    {
                        title: "Add",
                        route: "apps-live-chat-add",
                        permission: "liveChat.add",
                    },
                    {
                        title: "List",
                        route: "apps-live-chat-list",
                        permission: "liveChat.list",
                    },
                ],
            },
        ],
    },
    {
        title: "Comments",
        icon: "MessageCircleIcon",
        route: "apps-comment-list",
        permission: "Comments.list",
    },
    {
        title: "Posts",
        icon: "HashIcon",
        route: "apps-post-list",
        permission: "Posts.list",
    },
    {
        title: "Stories",
        icon: "VideoIcon",
        route: "apps-story-list",
        permission: "Stories.list",
    },
    {
        title: "Version",
        icon: "GitPullRequestIcon",
        route: "apps-version-list",
        permission: "Version.add",
    },
    {
        title: "Setting",
        icon: "ImageIcon",
        route: "apps-setting-update",
        permission: "Setting.show",
    },
    // {
    //     title: "Account Settings",
    //     route: "pages-account-setting",
    //     icon: "SettingsIcon",
    // },
    {
        title: "Profile",
        route: "pages-profile",
        icon: "Edit2Icon",
    },
    {
        header: "Wallet",
    },
    {
        title: "Wallet",
        icon: "CreditCardIcon",
        children: [
            {
                title: "Users Wallet",
                route: "apps-wallet-users-wallet",
                permission: "Wallet.wallet.show-user-wallet",
            },
            {
                title: "Exchange Rate",
                route: "apps-wallet-exchange-rate",
                permission: "Wallet.currency.add",
            },
            {
                title: "Admin log",
                route: "apps-wallet-admin-log",
                permission: "Wallet.admin-logs.list",
            },
            {
                title: "Totals",
                route: "apps-wallet-totals",
                permission: "Wallet.payment.total-payments",
            },
            {
                title: "Referal code",
                route: "apps-wallet-referal-code",
                permission: "",
            },
        ],
    },
    {
        title: "Withdraw",
        icon: "CreditCardIcon",
        children: [
            {
                title: "Requests",
                route: "apps-withdraw-requests",
                permission: "Wallet.wallet.show-user-wallet",
            },
            {
                title: "Config",
                route: "apps-withdraw-config",
                permission: "Wallet.currency.add",
            },
        ],
    },
    {
        header: "Store",
    },
    {
        title: "Slide Shows",
        icon: "LayoutIcon",
        children: [
            {
                title: "Add Slide Show",
                route: "apps-store-slide-show-add",
            },
            {
                title: "All List",
                route: "apps-store-slide-show-list",
            },
            {
                title: "Actives List",
                route: "apps-store-slide-show-active-list",
            },
        ],
    },
    {
        title: "Store items",
        icon: "ServerIcon",
        children: [
            {
                title: "Add",
                route: "apps-store-items-add",
            },
            {
                title: "List",
                route: "apps-store-items-list",
            },
        ],
    },
    {
        header: "Contest",
    },
    {
        title: "Contests",
        icon: "CastIcon",
        children: [
            {
                title: "Add",
                // route: "apps-contest-add",
            },
            {
                title: "List",
                // route: "apps-contest-list",
            },
        ],
    },

    // {
    //     title: "Contest stages",
    //     icon: "ColumnsIcon",
    //     children: [
    //         {
    //             title: "Add",
    //             route: "apps-contest-stage-add",
    //         },
    //         {
    //             title: "List",
    //             route: "apps-contest-stage-list",
    //         },
    //     ],
    // },
    // {
    //     title: "Contest groups",
    //     icon: "LayoutIcon",
    //     children: [
    //         {
    //             title: "Add",
    //             route: "apps-contest-group-add",
    //         },
    //         {
    //             title: "List",
    //             route: "apps-contest-group-list",
    //         },
    //     ],
    // },
    {
        header: "Log",
    },
    {
        title: "Admin Log",
        icon: "CastIcon",
        children: [
            {
                title: "List",
                route: "apps-admin-log-list",
            },
        ],
    },
];
